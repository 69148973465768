<template>
  <div class="error-page error-page--404">
    <v-card class="text-center w-full pa-6" max-width="500px">
      <v-img src="../../assets/404.svg" max-height="400px" />
      <div class="display-2 mt-16">How did you get here?</div>
      <div class="mt-6 mb-10">Sorry we can't seem to find the page you're looking for.</div>
      <v-btn color="primary" @click="$router.go(-1)" link block>Send me Back</v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "NotFound",

  layout: "error",

  metaInfo() {
    return { title: "404 Not Found" };
  },
};
</script>
